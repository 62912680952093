.socialIconDivDrag {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  background: rgb(255, 255, 255);
  align-items: center;
  border-radius: 17px;
  box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 17%);
  aspect-ratio: 1;
  justify-content: space-evenly;
  /* padding: 8px 8px; */
  position: relative;
  margin: 0 8px 10px 0;

  /* cursor: pointer; */
}

.socialIconName {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  color: var(--blackWhite);
  margin-top: 3px;
  text-align: center;
}
.socialIcon {
  width: 25%;
}

.icons {
  /* display: flex;
  flex-direction: row;
  
  flex-wrap: wrap; */
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 15px;
}
